@import url('https://fonts.googleapis.com/css2?family=Allura&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,500&display=swap');



.hold {
    margin-top: 6rem;
    background-color: antiquewhite;
    overflow-x: hidden;
}

.headHold {
    background-color: black;
    color: white;
}

.head {
    font-family: 'Poppins', sans-serif;
    font-size: 4rem;
    text-align: center;
}

.mainHold {
    background-color: antiquewhite;
    font-family: 'Poppins', sans-serif;
}

.mainHead {
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    margin-left: 4rem;
    font-weight: 600;

}

.mainL {
    margin-left: 4rem;
    margin-top: -2rem;
}

.mainL1 {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    font-weight: 500;
}

.mainL1r {
    font-family: 'Poppins', sans-serif;
    font-size: 1.4rem;
    text-align: center;
    font-weight: 500;
}

.mainL11 {
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    text-align: center;
}

.mainL111 {
    font-family: 'Poppins', sans-serif;
    font-size: 0.9rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.main {
    display: flex;
    justify-content: center;
}

.plate {
    margin-top: -8rem;
}
.regional{
    padding: 2rem;
}
.regionalHead {
    font-family: 'Dancing Script', cursive;
    font-size: 3rem;
    text-decoration: underline;
}

.regL {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.regL1,
.regL2,
.regL3,
.regL4 {
    border: 1px solid rgb(200, 205, 50);
    padding: 1rem;
}

.regL1Head {
    text-align: center;
}

.regTxt {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.coldHoldIn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 11rem;
}
.coldHoldIn2 {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15rem;
}

.cocktail {
    width: 29rem;
    height: 20rem;
    border-radius: 1rem;

}

.hotL {
    margin-top: 2rem;
}
.CHATGAP{
    margin-right: 10rem;
}
.gapFarsaan{
    margin-right: 10rem;
}
.panGap{
    margin-right: 10rem;
}
.partyHold{
    margin-bottom: 2rem;
}


@media screen and (max-width:768px) {
    .main {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .main .mainL{
        width: 100%;
        margin: 0;
        padding: 1rem;
        margin-top: -2rem;
    }
    .main .mainR{
        width: 95%;
    }
    .main .mainR img{
        width: 100%;
        margin: .5rem;
    }
    .regional{
        padding: 1rem;
        margin-top: -1rem;
    }
    .mainHead{
        font-size: 1.5rem;
    }
    .regHold{
        width: 100%;
    }
    .regL {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .regL1,
.regL2,
.regL3,
.regL4 {
    width: 25%;
    border: 1px solid rgb(200, 205, 50);
    padding: .5rem;
}
.mainL1r{
    font-size: .8rem;
}
.mainL111{
    font-size: .5rem;
    margin: 0rem;
}
.coldHoldIn{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 0;
}
.coldHoldIn img{
    width: 95%;
    margin: .5rem;
}
.coldHoldIn2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0rem;
}
.mainL1{
    margin: 1rem;
}
.mainHead{
    margin: 0;
    display: flex;
    justify-content: center;
    background-color: rgb(161, 146, 146);
}

}
