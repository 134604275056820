.hold {
  margin-top: 6rem;
  padding-bottom: 2rem;
}

.headHold {
  margin-top: 5rem;
  border: 2px solid black;
}

.divsHold {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.card {
  width: 24rem;
  box-shadow: 2px 2px 3px 1px rgb(212, 212, 212);
  border: 1px solid rgb(198, 198, 198);
  margin-bottom: 1rem;
}

.cardUp {
  width: 24rem;
  margin: auto;
  display: block;
}

.cardImg {
  width: 24rem;
  height: 17rem;
}

.cardDown {
  width: 22rem;
  background-color: white;
  border-top: 4px solid rgb(226, 1, 1);
  margin: auto;
  display: block;
  margin-top: -4rem;
  z-index: 1;
  position: relative;
  border-radius: 0.5rem;
}

.head {
  font-family: 'Allura', cursive;
  text-align: center;
  font-size: 2.2rem;
  font-weight: 600;
  color: rgb(226, 1, 1);
}

.txt {
  text-align: center;
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}