.hold {
    margin-top: 4rem;
}

.image {
    width: 23rem;
    margin: auto;
    display: block;
}

@media screen and (max-width:768px) {
    .hold {
        margin-top: 10rem;
    }
    
    .image {
        width: 5rem;
        margin: 1rem;
        display: block;
    }
}