@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');


.hold {
    background-color: rgb(255, 248, 240);
    font-family: 'Poppins', sans-serif;
}

.headHold {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.head {
    font-family: 'Poppins', sans-serif;
    font-size: 1.3rem;
    font-weight: 600;
    color: rgb(111, 111, 111);
}

.head2 {
    text-align: center;
    margin-top: -0.6rem;
    font-family: 'Poppins', sans-serif;
    font-size: 2.5rem;
    font-weight: 600;
    color: rgb(58, 58, 58);
}

.bottomHold {
    display: flex;
    justify-content: center;
    gap: 5rem;
}

.btm1 {
    width: 25vw;
    text-align: right;
}

.btm2 {
    width: 25vw;
}

.btm3 {
    width: 25vw;
    text-align: left;
}

.hd {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgb(76, 76, 76);
}

.txt {
    color: rgb(104, 104, 104);
}


.num {
    font-size: 2.5rem;
    font-weight: 600;
    margin-left: 1rem;
    color: rgba(210, 4, 4, 0.699);
}

.num2 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-right: 1rem;
    color: rgba(210, 4, 4, 0.699);
}


@media screen and (max-width:768px) {
    .hold {
        width: 100%;
        background-color: rgb(255, 248, 240);
        font-family: 'Poppins', sans-serif;
        margin-top: 10rem;
    }
    .bottomHold {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 2rem;
    }
    .hd {
        font-size: .9rem;
        font-weight: 600;
        color: rgb(76, 76, 76);
    }
    
    .txt {
        color: rgb(104, 104, 104);
        font-size: .6rem;
    }
    
    
    .num {
        font-size: .9rem;
        font-weight: 600;
        margin-left: 1rem;
        color: rgba(210, 4, 4, 0.699);
    }
    .num2 {
        font-size: .9rem;
        font-weight: 600;
        margin-right: 1rem;
        color: rgba(210, 4, 4, 0.699);
    }
    .head {
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        font-weight: 600;
        color: rgb(111, 111, 111);
    }
    
    .head2 {
        text-align: center;
        margin-top: -0.6rem;
        font-family: 'Poppins', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        color: rgb(58, 58, 58);
    }
}