@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

@import url('https://fonts.googleapis.com/css2?family=PT+Serif:wght@700&display=swap');


.hold {
    background-color: rgb(255, 241, 224);
    overflow-x: hidden;
}

.aboutUs {

    height: 17vw;
    width: 100%;
}

.logo {
    position: absolute;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 10vw;
    border-radius: 50%;

}

.story {

    position: absolute;
    top: 11rem;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-family: 'Cinzel', serif;
    color: white;

    background: rgba(0, 0, 0, 0.5);
    padding: 10px;
    z-index: 100;
}

.about {
    justify-content: center;

}

.about h1 {

    display: block;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 20px;
    width: 30rem;

    color: goldenrod;
    left: 35%;
    text-align: center;
    position: relative;
    font-size: 3rem;
    margin-bottom: 2rem;
    font-family: 'Cinzel', serif;


}

.banner {

    margin-left: 100px;
    padding: 3rem;
    margin-bottom: 100px;
    background-color: aliceblue;
    display: block;

    text-align: center;
    width: 40rem;
}

.banner img {
    border: 4px solid goldenrod;
    border-radius: 4px;
    height: 150px;
    position: relative;

    align-self: center;
    background-color: aliceblue;



}

.banner p {
    color: black;
    background-color: aliceblue;
    text-size-adjust: 50px;
    margin-block-start: 2em;
    margin-block-end: 2em;
    font-size: large;
    font-family: 'Cormorant', serif;
    font-style: normal;
    font-weight: 500;
    text-align: center;
    padding: 30px;
    border: 2px solid goldenrod;

}

.banner.text {
    position: relative;
    display: block;
    padding: 20px;
    background-color: aliceblue;
    padding-bottom: 2rem;
    border: goldenrod;

}

.containerBox {
    display: flex;

    flex-direction: row;
    padding: 20px;
}

.videoContainer {

    object-fit: contain;
    overflow-clip-margin: content-box;
    overflow: clip;


}

.vdo {
    border: 2px solid goldenrod;
    height: 80%;
    object-fit: cover;
    z-index: 0;
    border-radius: 10px;
}

.aim {
    height: 400px;
    display: flex;
    flex-direction: row;
    padding: 20px;
    margin-top: 4rem;
    margin-bottom: 5rem;
    justify-content: space-between;
    padding: 2rem 4rem;
}

.aimbox {
    border: 2px solid goldenrod;
    width: 30%;
    color: goldenrod;
    margin-bottom: 2rem;
    border-radius: 13px;
    height: 25rem;
    padding: 1rem;
    text-align: center;
    background-color: black;
}

.img {

    justify-content: center;
    border-radius: 50%;
    box-shadow: 5px 5px 5px #d5b70c;
    padding: 1rem;
    width: 10rem;
    overflow-clip-margin: content-box;
    overflow: clip;
}

.aimbox h3 {
    text-decoration: underline;
    margin-bottom: 8px;
    letter-spacing: 2px;
    margin-top: 2rem;
}

.aimbox p {
    margin-top: 2rem;
    font-size: 1rem;

}

.abt {
    background-image: url("./asset/about1.jpg");
    width: 100%;
    height: 50rem;

    background-size: 100% 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;

}

.abtIn {
    background-color: whitesmoke;
    width: 55vw;
    height: 40rem;
    margin-top: 5rem;
    border-top-left-radius: 2rem;
    border-top-right-radius: 7rem;

    border-bottom-left-radius: 7rem;
    border-bottom-right-radius: 2rem;
}

.abtInHd {
    text-align: center;
    color: rgb(2, 110, 2);
    font-size: 3.2rem;
    font-family: 'Playfair Display', serif;
    font-weight: bolder;
    line-height: 3.5rem;
}

.abtInTxt {
    text-align: center;
    width: 50vw;
    margin: auto;
    display: block;
    margin-bottom: 1.5rem;
    color: rgba(0, 14, 0, 0.772);
}

.missionHold {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 9rem;
    font-family: 'Poppins', sans-serif;
    background-color: rgb(150, 225, 37);
}

.left {
    margin-left: 2rem;
}

.abt2 {
    width: 40vw;
}

.txt {
    font-size: 1.6rem;
}

.txt2 {
    margin-top: 9rem;
    font-size: 2.7rem;
    font-weight: 600;
}

.hold3Txt {
    margin-top: 5rem;
    font-family: 'Poppins', sans-serif;
    font-size: 2rem;
    font-weight: 600;
    margin-left: 7rem;
    color: rgba(0, 0, 0, 0.833);
}

.hold3Txt2 {
    font-family: 'Poppins', sans-serif;
    margin-left: 11rem;
    font-size: 1.2rem;
    color: rgba(0, 0, 0, 0.806);
}

.satisfyHold {
    margin-top: 5rem;
    background-color: whitesmoke;
}

.styTxt1 {
    padding-top: 2rem;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    color: rgb(216, 3, 3);
}

.styTxt2 {
    text-align: center;
    font-family: 'PT Serif', serif;
    font-size: 5rem;
    margin-top: -1.3rem;
    color: rgb(216, 3, 3);

}

.cardHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}

.card {
    width: 23rem;
    border: 2px solid rgba(0, 0, 0, 0.073);
    border-radius: 0.5rem;
}

.iconHold {
    margin-top: 0.7rem;
}

.icon {
    width: 5rem;
    margin: auto;
    display: block;
}

.cardHd {
    font-family: 'PT Serif', serif;
    font-size: 1.6rem;
    font-weight: 600;
    text-align: center;
    color: rgb(216, 3, 3);
}

.cardTxt {
    font-family: 'Poppins', sans-serif;
    font-size: 1.2rem;
    text-align: center;
}
.txtM{
    font-size: 3rem;
    font-weight: 700;
    color: orangered;
    margin-bottom: -4rem;
}


@media screen and (max-width:768px) {
    .abt {
        background-image: url("./asset/about1.jpg");
        width: 100%;
        height: auto;
       background-size: 100% 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 5rem;
        margin-top: 0rem;
    
    }
    
    .abtIn {
        background-color: whitesmoke;
        width: 75vw;
        height: auto;
        margin-top: 5rem;
        border-top-left-radius: 2rem;
        border-top-right-radius: 7rem;
    
        border-bottom-left-radius: 7rem;
        border-bottom-right-radius: 2rem;
        padding: 1rem;
    }
    
    .abtInHd {
        text-align: center;
        color: rgb(2, 110, 2);
        font-size:2rem;
        font-family: 'Playfair Display', serif;
        font-weight: bolder;
        line-height: 2rem;
    }
    
    .abtInTxt {
        text-align: center;
        width: 100%;
        margin: 0rem;
        display: block;
        margin-bottom: 1.5rem;
        color: rgba(0, 14, 0, 0.772);
    }
    .missionHold {
        width: 100%;
        display: flex;
        flex-direction: column;
        font-family: 'Poppins', sans-serif;
        background-color: rgb(150, 225, 37);
        margin-top: -5rem;
    }
    
    .left {
        margin-left: 2rem;
        width: 100%;
    }
    .txtM{
        font-size: 2rem;
        font-weight: 700;
        color: orangered;
        margin-bottom: 0rem;
    }
    .txt2{
        font-size: 2rem;
        margin-top: 0rem;
    }
    .right{
        width: 100%;
        margin-top: -10rem;
    }
    .right img{
        width: 95%;
        margin: .5rem;
    }
    .hold3{
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        margin-bottom: -4rem;
    }
    .hold3Txt {
        margin-top: 0rem;
        font-family: 'Poppins', sans-serif;
        font-size: 1.8rem;
        font-weight: 600;
        margin-left: 0rem;
        color: rgba(0, 0, 0, 0.833);
    }
    
    .hold3Txt2 {
        width: 100%;
        font-family: 'Poppins', sans-serif;
        margin-left: 0rem;
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.806);
        margin-top: -1rem;
    }
    .styTxt2 {
        text-align: center;
        font-family: 'PT Serif', serif;
        font-size: 2rem;
        margin-top: 0rem;
        color: rgb(216, 3, 3);
    
    }
    
}