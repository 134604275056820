 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.hold{
    background:linear-gradient(130deg ,white 30%, rgb(227, 158, 30) 10%);
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 6rem;
    align-items: top;
    padding: 1rem 0;

}

.hold .one .logo{
  width: 8rem;
  margin-right: 3rem;
}
.head3{
    margin-top: -.5rem;
}
.p1{
    margin-top: -1rem;
}
.head{
    margin-bottom: 0rem;
}
.list{
    text-decoration: none;
    list-style-type: none;
}
.r{
    display: flex;
    flex-direction: row;
    gap: .5rem;
}
.r img{
    width: 1.8rem;
    height: 1.8rem;
    
}

@media screen and (max-width:786px) {
    .hold{
        background:linear-gradient(130deg ,white 30%, rgb(227, 158, 30) 10%);
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1.5rem;
        align-items: top;
        padding: 1rem 0;
    
    }
    .one{
        width: 23%;
    }
    .hold .one .logo{
        width: 4rem;
        margin-right: 0rem;
      }
    .head{
        font-size: .9rem;
    }
    .add{
        font-size: .7rem;
    }
    .one p{
        font-size: .5rem;
        width: 6rem;
    }
    .one i{
        font-size: .5rem;
    }
    .one .list{
        font-size: .5rem;
    }
    .r img{
        width: 1rem;
        height: 1rem;
    }
    .length22{
        width: 5rem;
    }
}
