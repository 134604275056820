.container{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    background-color: whitesmoke;
}
.container h1{
    font-size: 2.3rem;
    margin: 2rem;
    letter-spacing: 1px;
}
.imgContainer{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    margin-bottom: 5rem;
}
.imgContainer .row{
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 3rem;

}
.row img {
    width: 16rem;
    height: 12rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    transition: .3s ease-in-out;
}
.row video {
    width: 16rem;
    height: 12rem;
    border-top-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
    transition: .3s ease-in-out;
}
.row video:hover {
    scale: 1.1;
}




@media screen and (max-width:768px) {
    .container{
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 100%;
        background-color: whitesmoke;
        margin-top: 0rem;
    }
    .container h1{
        font-size: 2rem;
        margin: 1rem;
        letter-spacing: 1px;
    }
    .imgContainer{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 5rem;
    }
    .imgContainer .row{
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 1rem;    
    }
    .row img {
        width: 5rem;
        height: 4rem;
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        transition: .3s ease-in-out;
    }
    .row video {
        width: 5rem;
        height: 4rem;
        border-top-left-radius: 1rem;
        border-bottom-right-radius: 1rem;
        transition: .3s ease-in-out;
    }
}