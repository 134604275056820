.hold {
    
    padding-top: 0.02px;
    padding-bottom: 4rem;
    background-image: linear-gradient(rgba(8, 8, 8, 0.42),
    rgba(0, 0, 0, 0.242)),
url("../asset/leaf.jpg");
}

.head {
    text-align: center;
    font-family: 'Varela Round', sans-serif;
    font-size: 2.7rem;
    font-weight: 600;
    color: white;
}

.head2{
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
    color: gray;
    margin-top: -2rem;
}

@media screen and (max-width:768px) {
    .head {
        text-align: center;
        font-family: 'Varela Round', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        color: white;
    }
    .head2{
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
        color: gray;
        margin-top: 0rem;
    }
}