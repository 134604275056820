.hold {
    margin-top: -.3rem;
    background-color: black;
    padding: 3rem 0;

}

.divHold {
    display: flex;
    justify-content: center;
    gap: 9rem;
    height: 25rem;
}

.left {
    justify-content: center;
    width: 40%;

}

.right {
    width: 40%;
    height: 100%;
    border-radius: 1rem;
}
.right video{
    width: 100%;
    height: 100%;
    border-radius: 1rem;
}



.plate {
    width: 100%;
    height: 100%;
}

.myimg {
    width: 2rem;
    height: 3rem;
}

.leftHead {
    color: #f7f4ed;
    text-align: center;
    font-size: 3rem;
    font-weight: 800;
    font-family: 'Varela Round', sans-serif;
}

.leftHead2 {
    color: red;
    text-align: center;
    font-weight: 1.2rem;
    margin-top: -1.5rem;
}

.leftTxt {
    color: white;
    text-align: center;
}

.btnHold {
    margin-top: 4rem;
}

.menuBtn {
    margin: auto;
    display: block;
    border-radius: 2rem;
    width: 13rem;
    height: 3.5rem;
    border: 4px solid rgb(227, 0, 0);
    color: red;
    font-size: 1.1rem;
}


@media screen and (max-width:768px) {
    .hold {
        width: 100%;
        margin-top: 0rem;
        background-color: black;
    }
    .divHold {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;

    }
    .left {
        width: 100%;
        margin-top: 10rem;
    }
    .leftTxt{
        margin: 0rem 1rem;
    }
    .right {
        width: 100%;
    }
    
}