.hold {
    background-image: linear-gradient(rgba(0, 0, 0, 0.274),
            rgba(0, 14, 23, 0.881)),
        url("./header.jpg");

    width: 100vw;
    height: 35vh;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    margin-bottom: 5rem;
    color: whitesmoke;

    display: flex;
    justify-content: center;
    align-items: center;
}

.txt {
    font-family: 'PT Serif', serif;
    font-size: 2.5rem;
    text-align: center;
}



@media screen and (max-width:768px) {
    .hold {
        background-image: linear-gradient(rgba(0, 0, 0, 0.274),
                rgba(0, 14, 23, 0.881)),
            url("./header.jpg");
    
        width: 100vw;
        height: 15vh;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    
        margin-bottom: 1rem;
        color: whitesmoke;
    
        display: flex;
        justify-content: center;
        align-items: center;
    }
}