@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@600&display=swap');

.hold {
    font-family: 'Poppins', sans-serif;
    background-color: whitesmoke;
    padding: 1rem 0;
}

.head {
    text-align: center;
    font-size: 1.2rem;
    font-weight: 500;
}

.head2 {
    text-align: center;
    font-size: 2.5rem;
    font-weight: 600;
    margin-top: -0.5rem;
}

.hold1 {
    display: flex;
    flex-direction: row;
    justify-content: center;

}

.h1 {
    width: 30vw;
    height: 60vh;
    background-color: white;
    border-radius: 0.5rem;
    font-size: .9rem;
}

.h1:hover {
    background-color: #8cac3d;
    transition: 300ms;
    color: whitesmoke;
}

.flower {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.icon {
    width: 7rem;
    height: 7rem;
    margin: auto;
    display: block;
    margin-top: 1rem;
}

.cardHd {
    text-align: center;
    font-size: 1.3rem;
    font-weight: 500;
}

.cardTxt {
    text-align: center;
}

.moreBtnHold {
    margin-top: 25vh;
}

.moreBtn {
    margin: auto;
    display: block;
    width: 12vw;
    height: 9vh;
    border: none;
    background-color: #a7ca4d;
    border-radius: 0.3rem;
    font-size: 1.1rem;
    font-weight: 500;

}

.moreBtn:hover {
    transition: 500ms;
    background-color: #8cac3d;
    color: white;
}

@media screen and (max-width:768px) {
    .hold{
        margin-top: -12rem;
    }
    .head {
        text-align: center;
        font-size: 1rem;
        font-weight: 500;
    }
    
    .head2 {
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        margin:0rem 1rem;
        margin-bottom: 1rem;
    }
    .h1 {
        width: 30vw;
        height: 60vh;
        background-color: white;
        border-radius: 0.5rem;
        font-size: .6rem;
    }
    .h1 img{
        width: 5rem;
        height: 5rem;
    }
    .h1 .flower{
        width: 30vw;
        height: 60vh;
    }
    .cardHd{
        font-size: 1rem;
    }
    
    
    
}