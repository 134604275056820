@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');


 .hold {
     width: 23rem;
     border: 2px solid #ce9836;
     border-radius: 0.5rem;
     font-family: 'Poppins', sans-serif;

 }

 .image {
     border-radius: 50%;
     width: 5rem;
     height: 5rem;
     margin: auto;
     display: block;
     padding-top: 11px;
     padding-bottom: 11px;

 }

 .txt {
     margin-top: 2rem;
     text-align: center;
     margin-left: 0.4rem;
     margin-right: 0.4rem;
     color: #ce9836;
 }

 .name{
    text-align: center;
    color: #ce9836;
    font-size: 1.2rem;
    font-weight: 500;
 }

 @media screen and (max-width:768px) {
    .hold {
        width: 95%;
        height: 20rem;
        border: 2px solid #ce9836;
        border-radius: 0.5rem;
        font-family: 'Poppins', sans-serif;
        margin: .5rem;
   
    }
}