.mainContainer{
    width: 100%;
    overflow-x: hidden;
}
.mainContainer .hold{
    margin-top: 7rem;
}
.imgContainer {
    width: 100%;
    padding: 3rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}
.imgContainer .row{
    display: flex;
    flex-direction: row;
    gap: 3rem;
}
.row img{
    position: relative;
    width: 16rem;
    height: 13rem;
    transition: .3s ease-in-out;
}
.row video{
    position: relative;
    width: 16rem;
    height: 13rem;
    transition: .3s ease-in-out;
}
.row img:hover{
    scale: 1.1;
}
.row video:hover{
    scale: 1.1;
}

@media screen and (max-width:768px) {
    .imgContainer {
        width: 100%;
        padding: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .imgContainer .row{
        width: 100%;
        display: flex;
        flex-direction: row;
        gap: .5rem;
    }
    .row img{
        position: relative;
        width: 5rem;
        height: 5rem;
        transition: .3s ease-in-out;
    }
    .row video{
        position: relative;
        width: 5rem;
        height: 5rem;
        transition: .3s ease-in-out;
    }
    .row img:hover{
        scale: 1.1;
    }
    .row video:hover{
        scale: 1.1;
    }
}