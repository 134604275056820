.hold {
    color: black;
    margin-top: 5rem;
    width: 100%;
    overflow: hidden;
}
.vid {
    width: 100%;
    height: 60%;
}
.log{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}


@media screen and (max-width:768px) {
    .hold {
        width: 100%;
        margin-top: 5rem;
        position: relative;
    }
    .vid {
        width: 100%;
    }
    .log{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 8rem;
    }
}