@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&display=swap');

.hold {
    background-color: whitesmoke;
    padding-bottom: 5rem;

}

.divhold {
    display: flex;
    justify-content: center;
    gap: 8rem;
}

.left {
    width: 35%;

}

.right {
    margin-top: 7rem;
    margin-bottom: 2rem;
    height: 281.5px;
    width: 500px;
    display: flex;
    justify-content: center;
    gap: 1rem;
}

.wedImg {
    width: 32.9%;
    height: 39.8%;
    position: absolute;
    object-fit: cover;
    z-index: 0;

}

.leftHead {
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-size: 2rem;
    color: rgb(227, 2, 2);
}

.leftHead2 {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 1.1rem;
    margin-top: -1.7rem;
    font-weight: 500;
}

.leftTxt {
    text-align: center;
    margin-top: 3rem;
    font-family: 'Poppins', sans-serif;
    font-size: 1rem;
    color: rgb(52, 52, 52);
}

.wedImg2 {
    width: 15rem;
    height: 21rem;
    object-fit: cover;
    border-radius: 0.5rem;
}


@media screen and (max-width:768px) {
    .divhold{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 0rem;
        margin-top: -4rem;
    }
    .left{
        width: 100%;
    }
    .leftHead {
        text-align: center;
        font-family: 'Dancing Script', cursive;
        font-size: 1.7rem;
        color: rgb(227, 2, 2);
        margin-top: -2rem;
    }
    
    .leftHead2 {
        text-align: center;
        font-family: 'Poppins', sans-serif;
        font-size: 1.3rem;
        margin-top: -1.2rem;
        font-weight: 500;
    }
    
    .leftTxt {
        margin-top: 0rem;
        font-family: 'Poppins', sans-serif;
        font-size: 1rem;
        color: rgb(52, 52, 52);
        margin: 0rem 1rem;
    }
    .right{
        width: 100%;
        margin-top: 1rem;
    }
    .right img{
        width: 10rem;
        height: 12rem;
    }
    .right video{
        width: 10rem;
        height: 12rem;
    }

}