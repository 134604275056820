.hold {
    margin-top: 6rem;
    overflow-x: hidden;
}

.txt1 {
    text-align: center;
    font-family: 'PT Serif', serif;
    font-size: 2.5rem;
}

.txt2 {
    text-align: center;
    margin-top: -1.5rem;
}

.txt3 {
    font-family: 'PT Serif', serif;
    font-size: 1.5rem;
}

.divHold {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 5rem;
    align-items: center;
}
.contactImg {
    width: 30rem;
}

.icon {
    width: 4rem;
}
.divHold .left{
    width: 40%;
}
.in , .in2{
    width: 90%;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
}
.btn{
    width: 95%;
    padding: .5rem 1rem ;
    border: none;
    background-color: rgb(52, 222, 52);
    font-weight: 700;
}


@media screen and (max-width:768px){
    .divHold {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 1rem;
    }
    .divHold .left{
        width: 95%;
    }
    .contactImg {
        width: 95%;
    }
    .btn{
        width: 95%;
        padding: .5rem 1rem ;
        border: none;
        background-color: rgb(52, 222, 52);
        font-weight: 700;
        margin: .5rem;
    }
}