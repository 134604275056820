 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500&family=Varela+Round&display=swap');


 .hold {
     background-color: whitesmoke;
     font-size: 2rem;
     padding-top: 1rem;
 }

 .head {
     text-align: center;
     font-family: 'Varela Round', sans-serif;
     font-size: 2.7rem;
     font-weight: 600;
 }